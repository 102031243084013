import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import "./gridpane.scss"

export default class GridCol extends Component {

  static propTypes = {

  }

  static defaultProps = {

  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    return (
    <div className="container__inner__col50">
        {this.props.children}
    </div>
    )
  }

}
