import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./quoteSlider.scss"


export default class QuoteSlider extends Component {

  static propTypes = {
  }

  static defaultProps = {
  }

  componentDidMount() {
    this.quoteSlider = React.createRef();
  }

  render() {

    var settingsSlider = {
      arrows: false,
      autoplay: true,
      dots: false,
      infinite: true,
      speed: 600,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: 'ease-in-out',
      className: 'gallery',
      pauseOnHover: false
    };

    return (
      <div className="quote-slider-container">
        <div className="quote-slider-wrapper" ref={this.quoteSlider}>
          <Slider  {...settingsSlider} >

            <div className="quote">          
              <div className="quote-title">#1 Top 10 U.S. Cities for International Foods</div>
              <div className="quote-source">- Travel Channel</div>
            </div>
            <div className="quote">
              <div className="quote-title">#1 West Coast Food City</div>
              <div className="quote-source">- USA Today</div>
            </div>
            <div className="quote">
              <div className="quote-title">#3 Among Cities Where Millennials are Moving</div>
              <div className="quote-source">- SmartAsset</div>
            </div>
            <div className="quote">
              <div className="quote-title">#5 Among Best Cities for Diversity in STEM</div>
              <div className="quote-source">- SmartAsset</div>
            </div>

          </Slider>
        </div>
      </div>
    )
  }
}
