import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { Place03 } from '../images/place-03'

import { LifeSlider01 } from '../images/life-slider01';
import { LifeSlider02 } from '../images/life-slider02';
import { LifeSlider03 } from '../images/life-slider03';
import { LifeSlider04 } from '../images/life-slider04';

import "./inlineSlider.scss"


export default class InlineSlider extends Component {

  static propTypes = {
  }

  static defaultProps = {
  }

  componentDidMount() {
    this.inlineSlider = React.createRef();
  }

  render() {

    var settingsInline = {
      arrows: false,
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 600,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: 'ease-in-out',
      className: 'gallery'
    };

    return (
      <div className="inner-slider-wrapper" ref={this.inlineSlider}>

        <Slider  {...settingsInline} >
          <LifeSlider01 />
          <LifeSlider02 />
          <LifeSlider03 />
          <LifeSlider04 />
        </Slider>

      </div>
    )
  }

}
