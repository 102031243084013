import Link from "../shared/customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import { Life03 } from '../images/life-03'
import { Life04 } from '../images/life-04'
import { Life05 } from '../images/life-05'

import { Place02 } from '../images/place-02'

import { FactSheetsBridge } from '../images/fact-sheets-bridge'
import { FactSheetsBridge2 } from '../images/fact-sheets-bridge2'
import { ScbArchitects } from '../images/scb-architects'
import { TmgPartners } from '../images/tmg-partners'

import { Leasing01 } from "../images/leasing-01"
import { Leasing03 } from "../images/leasing-03"
import { Leasing04 } from "../images/leasing-04"
import { Leasing05 } from "../images/leasing-05"

import TmgLogo from '../../assets/tmg-logo.svg'
import ScbLogo from '../../images/scb_logo.png'

import GridPane from "../grid/gridpane"
import GridCol from "../grid/gridcol"
import GridImage from "../grid/gridimage"

import AnimHeader from "../shared/animheader"
import ContentBlock from "../shared/contentblock"

import "./content-block-2col.scss"

var specPDFs = require.context('../../images/specs_pdfs', true)

export default class ContentBlock2Col extends Component {

  static propTypes = {
    imgPos: PropTypes.string,
    imgSrc: PropTypes.string,
    animTitle: PropTypes.array,
    descList: PropTypes.array,
    leftWidth: PropTypes.number,
    rightWidth: PropTypes.number,
    contentPadding: PropTypes.bool,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    titleAlign: PropTypes.string,
    titleSize: PropTypes.string,
    brochureLink: PropTypes.bool

  }

  static defaultProps = {
    imgPos: 'right',
    animText: '',
    imgSrc: '',
    animTitle: [],
    descList: [],
    rightWidth: 50,
    leftWidth: 50,
    contentPadding: true,
    buttonLink: '',
    buttonText: '',
    titleAlign: 'center',
    titleSize: 'normal',
    brochureLink: false
  }

  imageTypes = {
    'Life03': Life03,
    'Life04': Life04,
    'Life05': Life05,
    'Place02': Place02,
    'Leasing01': Leasing01,
    'Leasing03': Leasing03,
    'Leasing04': Leasing04,
    'Leasing05': Leasing05,
    'FactSheetsBridge':FactSheetsBridge,
    'FactSheetsBridge2':FactSheetsBridge2,
    'TmgPartners': TmgPartners,
    'ScbArchitects': ScbArchitects
  }

  ImageSelector = (imgName) => {
    const ImageComponent = this.imageTypes[imgName];
    if(ImageComponent){
      return <ImageComponent />
    }
  }

  constructor(props) {
    super(props)
    this.contentBlock2Col = React.createRef();
  }

  componentDidMount() {
  }

  render() {
    let tour_flyer_pdf = 'TT-Tour_Flyer';

    return (
      <div ref={this.contentBlock2Col} className={`block-content-2col img-` + this.props.imgPos}>
        <div className="block-content-2col-inner">
          <div className="content-2col content-2col-text" style={{flexBasis: this.props.rightWidth + `%`, padding: this.props.contentPadding ? '10%' : '0px'}}>
            
            {this.props.imgSrc == 'TmgPartners' &&
              <TmgLogo />
            }
            {this.props.imgSrc == 'ScbArchitects' &&
              <img src={ScbLogo} className={`scbLogo`}/>
            }
            <ContentBlock titleSize={this.props.titleSize} titleAlign={this.props.titleAlign} typeText={this.props.animTitle} descText={this.props.animText} descList={this.props.descList} descAlign={this.props.imgPos} buttonLink={this.props.buttonLink} buttonText={this.props.buttonText} />
          </div>
          <div className="content-2col content-2col-image" style={{flexBasis: this.props.leftWidth + `%`}} >
            {this.props.brochureLink && 
            <a className="btn solid-bckg custom-link brochure-link" target="_blank" href={specPDFs(`./${tour_flyer_pdf}.pdf`)} >Download Tour Flyer (PDF)</a>}

            {this.ImageSelector(this.props.imgSrc)}

          </div>
        </div>
      </div>
    )
  }
}
