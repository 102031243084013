import Link from "./customlink.js"
import PropTypes from "prop-types"
import React, { Component } from 'react'

import ScrollMagic from 'ScrollMagic'

import VisibilitySensor from 'react-visibility-sensor'

import { TimelineMax, TweenMax, TweenLite, Linear, Power4, Elastic, SloMo, Back, CSSPlugin } from 'gsap';
import CSSRulePlugin from "gsap/CSSRulePlugin";

import "./animheader.scss";

export default class contentBlock extends Component {
  
  static propTypes = {
    typeText: PropTypes.array,
    descAlign: PropTypes.string,
    descList: PropTypes.array,
    fullLink: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonText: PropTypes.string,
    mode: PropTypes.string,
    titleAlign: PropTypes.string,
    titleSize: PropTypes.string
  }

  static defaultProps = {
    typeText: [],
    descList: [],
    descAlign: `left`,
    descText: ``,
    mode: `scroll`,
    titleAlign: `center`,
    titleSize: 'normal'
  }

  constructor(props) {
    super(props)

    this.state = {
      'sensorEnabled': true,
      'buttonClass': `btn`
    }

    this.cursorEle = React.createRef();
    this.ctaButton = React.createRef();
  }

  componentDidMount() {

    var _self = this;

    this.hasButton = false;

    if (this.props.buttonLink && this.props.buttonText) {
      this.hasButton = true;
    }
  
    if (this.props.typeText[0]){
      TweenMax.set(this.cursorEle.current, { opacity: 0 });
      if (this.hasButton) TweenMax.set(this.ctaButton.current, { opacity: 0 });

      if (this.props.inverted) {
        this.state.buttonClass = `btn btn-inverted`;
      }

      TweenMax.to(_self.cursorEle.current, .5, { opacity: 1 });
    }
  }


  descriptionOutput = () => {
    if (this.props.descText != ''){
      return <div dangerouslySetInnerHTML={{__html: this.props.descText}} />;
    }else {
      return <div className="content-list">
          <ul>
          {this.props.descList.map((value, index) => (
            <li className="content-list-level-1" key={index}>
            {value[0]}
            {value[1] &&
              <ul className="test">

                {value[1].map((value_2, index_2) => (
                  <li className="content-list-level-2" key={index_2}>
                    {value_2}
                  </li>
                ))}
              </ul>
            }
            </li>
          ))}
          </ul>
        </div>
    }
  }

  render() {

    let headerRender = ``;
    let headerButton = ``;
    let TitleMarkup;

    if (this.props.titleSize == 'normal'){
      TitleMarkup = `h2`;
    } else if(this.props.titleSize == 'small') {
      TitleMarkup = `h3`;
    } else if(this.props.titleSize == 'large') {
      TitleMarkup = `h1`;
    }

    if (this.hasButton) {
      headerButton =  
        <Link to={this.props.buttonLink} className={this.state.buttonClass}>{this.props.buttonText}</Link>
    }

    const headerInner = 
      <div className="block-content">
        {this.props.typeText[0] &&
          <TitleMarkup className={`typeHeader ${this.props.titleAlign}`}>
              {this.props.typeText}
            <span className="header-cursor" ref={this.cursorEle}></span>
          </TitleMarkup>
        }

        <div className={`descText ${this.props.descAlign}`}>

          <span>{this.descriptionOutput()}</span>     
          
          <div ref={this.ctaButton}>
          {headerButton}
          </div>

        </div>
      </div>;

      if (this.props.fullLink) {
        headerRender = 
            <Link to={this.props.fullLink}>
            {headerInner}
            </Link>;
      } else {
        headerRender = headerInner;
      }

    return (

      <VisibilitySensor onChange={this.textAnim} active={this.state.sensorEnabled}>

        {headerRender}

      </VisibilitySensor>

    )
  }

}
