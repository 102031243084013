import React, { Component, useState } from 'react'

import SEO from "../components/seo"

import Layout from "../components/layout/layout"
import SectionContainer from "../components/layout/sectioncontainer"

import Hero from "../components/shared/hero"
import InlineSlider from "../components/sections/inlineSlider"
import QuoteSlider from "../components/sections/quoteSlider"

import GridPane from "../components/grid/gridpane"
import GridImage from "../components/grid/gridimage"
import GridContentBlock from "../components/grid/gridcontentblock"
import ConnectBlock from "../components/sections/connectblock"

import ContentBlock2Col from "../components/sections/content-block-2col"

import FirstFriday from "../images/first-friday.mp4"

import AnimHeader from "../components/shared/animheader"

import { Life04 } from '../components/images/life-04';
import { Life04bis } from '../components/images/life-04bis';
import { Life05 } from '../components/images/life-05';
import { Life05bis } from '../components/images/life-05bis';
import { Life06 } from '../components/images/life-06';
import { Life07 } from '../components/images/life-07';
import { Life08 } from '../components/images/life-08';
import { Life11 } from '../components/images/life-11';
import { Life12 } from '../components/images/life-12';
import { Life12bis } from '../components/images/life-12bis';
import { Life12ter } from '../components/images/life-12ter';
import { Life21 } from '../components/images/life-21';
import { Life22 } from '../components/images/life-22';
import { Life23 } from '../components/images/life-23';

import Train from "../assets/train.svg";
import Car from "../assets/car.svg";
import Boat from "../assets/boat.svg";
import Bus from "../assets/bus.svg";


export default class WorkLifePage extends Component {

  constructor({props, location}) {
    super(props);

    this.locationState = location.state;
    this.layout = React.createRef();
    this.signalAnimComplete = false;

    this.state = {
      'signalAnimComplete': false,
    }
  }

  componentDidMount() {
    this.toggleGallery = this.layout.current.toggleGallery;
    this.navSwitchCallback = this.layout.current.navSwitchCallback;
    this.progressCheckCallback = this.layout.current.progressCheckCallback;
    this.layout.current.runSignalAnim(() => { this.setState({'signalAnimComplete': true }) })
  }

  render() {
    return (
      <Layout ref={this.layout}>
        <SEO title="Work/Life Experience" description="Discover the Bay Area’s best-kept secret: vibrant Uptown Oakland. Find out what a great commute and community look like." />
        
        <Hero pageClass="worklife" headerText={[`work/life`,`experience`]}
              descText="Discover the Bay Area’s best-kept secret: vibrant Uptown Oakland. Find out what a great commute and community look&nbsp;like."
              locationState={this.locationState}
              eyeBrowText="THE NEIGHBORHOOD"
              navSwitchCallback={this.navSwitchCallback}
              progressCheckCallback={this.progressCheckCallback} />


          {/* SECTION 1 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="first-section" section="worklife" dashIncrement="20" inverted={true} navSwitchCallback={this.navSwitchCallback}>
            <AnimHeader typeText={[`be on`,`your employees’`,`wavelength`]} descText={`Connect your company to Oakland’s deep pool of creative talent and fresh thinking.  An Uptown Oakland address positions you where many of your employees are today—and will be tomorrow.`} descAlign="right"/>
            <div className="video-wrapper">
              <div className="video-inner">
                <video playsInline muted={true} loop autoPlay>
                  <source src={FirstFriday} type="video/mp4" />
                </video>
              </div>
            </div>
          </SectionContainer>

          {/* SECTION 2 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="worklife-section2" dashIncrement="25" inverted={false} navSwitchCallback={this.navSwitchCallback}>
            <AnimHeader typeText={[`an optimal`,`commute`]} 
                        descText="Uptown Oakland earns a 96 Walk Score and is optimal for all types of commuting: walking, biking, driving, ferrying, skateboarding, scooting, riding BART, or taking the AC Transit bus." 
                        descAlign="right"/>
              
            <div className="inline-slider-section">
              <InlineSlider />
            </div>

            <ContentBlock2Col 
              imgSrc="Life03" 
              imgPos="right" 
              rightWidth={40}
              leftWidth={60}
            />

          </SectionContainer>

          {/* SECTION 4 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="worklife-section4" dashIncrement="35" inverted={true} navSwitchCallback={this.navSwitchCallback}>
            <div className="full-width-img">
              <Life04 />
              <div className="worklife-section4-content">
                <AnimHeader typeText={[`beat the`,`bridge`]} descText="Telegraph Tower’s convenient East Bay location helps commuters avoid the Bay Bridge—or reverse-commute it." descAlign="right" titleAlign="right"/>
              </div>
              <div className="worklife-section4-info">
                <div className="worklife-section4-info-inner">
                  <div className="mobile-description">Telegraph Tower’s convenient Uptown Oakland location helps commuters avoid the Bay Bridge. Or at least reverse-commute it.</div>
                  <ul>
                    <li><span className="info-label"><Train /></span>San Francisco – 10-20 min</li>
                    <li><span className="info-label"><Car /></span>Silicon Valley - 1 hr</li>
                    <li><span className="info-label"><Boat /></span>North Bay – 45 minutes</li>
                    <li><span className="info-label"><Bus /></span>East Bay – 15-25  min</li>
                  </ul>
                </div>
              </div>
            </div>
          </SectionContainer>

          {/* SECTION 5 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} dashIncrement="45" id="worklife-section5" inverted={false} navSwitchCallback={this.navSwitchCallback}>
            <AnimHeader typeText={[`options for`,`better homes`, `and better lives`]} descText="The East Bay offers the widest variety of cost-effective living options for your employees from single-family homes in charming neighborhoods to urban lofts. Not to mention thousands of new apartments and condos being built right now in Oakland—walking and biking to work will be a real option." descAlign="right"/>
            <GridPane type="grid3">

              <GridImage position="top-left">
                <Life05 />
              </GridImage>

              <GridImage position="bot-left">
                <Life04bis />
              </GridImage>

              <GridImage position="mid-right" gradientType="yellowred">
                <GridContentBlock contentType="quote" content={[`Over 9,000 new homes will be delivered in the next two years (within 10 minutes of the site)*`]} footnote={`data source: oaklandca.gov`} fontSize={`large`}/>
              </GridImage>

            </GridPane>
          </SectionContainer>

          {/* SECTION 6 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="worklife-section6" dashIncrement="50" inverted={true} navSwitchCallback={this.navSwitchCallback}>
            <div className="full-width-img">
              <Life05bis />
            </div>
          </SectionContainer>

          {/* SECTION 7 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="worklife-section7" dashIncrement="60" inverted={false} navSwitchCallback={this.navSwitchCallback}>
          <AnimHeader typeText={[`live it up`, `in uptown`]} descText="Telegraph Tower puts you in the center of the East Bay’s dazzling array of artisan and Michelin worthy cultural and culinary adventures. Explore breweries, distilleries, galleries, farmer’s markets, food trucks, street fairs, artisan foods, clubs, and live music. Discover 17 local coffee roasters and over 2000 coffee shops that make Oakland of the the top three coffee cultures in the US." buttonText={`View Interactive Map`} buttonLink={`/leasing#leasing-section-map`} descAlign="right"/>
            <GridPane type="grid1">
              
              <GridImage position="mid-left">
                <Life06 />
              </GridImage>

              <GridImage position="top-right">
                <Life07 />
              </GridImage>
              
              <GridImage position="bot-right">
                <Life08 />
              </GridImage>

            </GridPane>
          </SectionContainer>

          {/* SECTION 8 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="worklife-section8" dashIncrement="65" inverted={false} navSwitchCallback={this.navSwitchCallback}>
            <QuoteSlider />
          </SectionContainer>

          {/* SECTION 9 */}
          <SectionContainer  dashDetect={this.state.signalAnimComplete} id="worklife-section9" dashIncrement="75" inverted={false} navSwitchCallback={this.navSwitchCallback}>
            <AnimHeader typeText={[`260 days`,`of sun`]} descText="The best weather in the Bay Area means you and your family can take advantage of the East Bay’s extensive network of parks, lakes, and beaches; and enjoy every outdoor activity imaginable from kayaking to walking your dog." descAlign="right"/>
            <GridPane type="grid3">

              <GridImage position="top-left">
                <Life11 />
              </GridImage>
              <GridImage position="bot-left" textContent={false} gradientType="yellowred-2">
                <GridContentBlock contentType="quote" content={[`Walk, jog, bike, swim, picnic, kayak, play, explore, shop, and make new discoveries all year round.`]} fontSize={`large`}/>
              </GridImage>
              <GridImage position="mid-right">
                <Life12 />
              </GridImage>

            </GridPane>
          </SectionContainer>

          {/* SECTION 10 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="worklife-section10" dashIncrement="80" inverted={false} navSwitchCallback={this.navSwitchCallback}>
          <AnimHeader typeText={[`turn diversity`,`programs into`,`a belonging`,`program`]}  />
            <GridPane type="grid1">
              
              <GridImage position="mid-left">
                <Life12bis />
              </GridImage>

              <GridImage position="top-right" textContent={false} gradientType="yellowred-3">
                <GridContentBlock contentType="list" content={["Most female tech workers in the Bay Area*", "Most ethnically diverse city in the US*","41% of Oakland residents are bilingual**"]} footnote={`data source: *oaklandca.gov | **datausa.io`}/>
              </GridImage>

              <GridImage position="bot-right">
                <Life12ter />
              </GridImage>

            </GridPane>
          </SectionContainer>

          {/* SECTION 11 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="worklife-section12" dashIncrement="85" inverted={false} navSwitchCallback={this.navSwitchCallback}>
          <AnimHeader typeText={[`at the`,`corner of art`,`and culture`]} descText="On the first Friday of every month, you’ll find yourself at the center of an art- and community-centered experience like no other: Art Murmur. Featuring the freshest, most authentic Bay Area art, galleries, music, theater and guided tours. Art Murmur brings 30,000 visitors to Uptown every month and Telegraph Tower sits at the corner where it all begins." descAlign="right"/>
            <GridPane type="grid1">
              
              <GridImage position="mid-left">
                <Life21 />
              </GridImage>

              <GridImage position="top-right">
                <Life22 />
              </GridImage>

              <GridImage position="bot-right">
                <Life23 />
              </GridImage>

            </GridPane>
          </SectionContainer>


          {/* SECTION 12 */}
          <SectionContainer dashDetect={this.state.signalAnimComplete} id="worklife-section13" dashIncrement="90" inverted={false} navSwitchCallback={this.navSwitchCallback}>
            <ConnectBlock />
          </SectionContainer>


      </Layout>
    )
  }
}